//Import Images
// import athlete from './img/athlete-small.png';
// import goodtimes from './img/goodtimes-small.png';
// import theracer from './img/theracer-small.png';
// import goodtimes2 from './img/good-times2.jpg';
// import theracer2 from './img/the-racer2.jpg';
// import athlete2 from './img/athlete2.png';
import breathemohr2 from './img/breathemohr-2.JPG';
import breathemohr3 from './img/breathemohr-3.JPG';
import thoughts2 from './img/thoughts-2.JPG';
import thoughts3 from './img/thoughts-3.JPG';
// import h2h2 from './img/h2h-2.jpg';
import h2h2 from './img/happy2help_next_2.png';
import h2h3 from './img/h2h-3.jpg';

export const ReferenceData = () => {
  return [
    {
      title: 'Breathe Mohr',
      mainImg: breathemohr2,
      secondaryImg: breathemohr3,
      url: '/work/breathe-mohr',
      infos: [
        {
          title: 'Buchungssystem',
          description:
            'Termine können direkt über die Homepage gebucht werden und es werden automatisch Mails mit Termin an alle Teilnehmer*innen verschickt.',
        },
        {
          title: 'Videoeinbindung',
          description: 'Automatische Videowiedergabe auf der Startseite.',
        },
        {
          title: 'Animationen',
          description: 'Elemente der Website wurden nach Wunsch animiert.',
        },
      ],
    },

    {
      title: 'happy2help',
      mainImg: h2h2,
      secondaryImg: h2h3,
      url: '/work/happy2help',
      infos: [
        {
          title: 'Projekte finden',
          description:
            'Gemeinnützige Organisationen suchen freiwillige Helfer*innen für die gute Sache.',
        },
        {
          title: 'Einfach engagieren',
          description:
            'Unkompliziert und schnell ein Profil erstellen und Hilfe anbieten.',
        },
        {
          title: 'Direktnachrichten',
          description: 'Direkt auf der Seite mit der Organisation austauschen.',
        },
      ],
    },
    {
      title: 'thoughts',
      mainImg: thoughts2,
      secondaryImg: thoughts3,
      url: '/work/thoughts',
      infos: [
        {
          title: 'Postings',
          description:
            'Kurze Postings verfassen, Leuten folgen und ihre Posts auf der Startseite sehen.',
        },
        {
          title: 'Direct Messages',
          description: 'Einzel- und Gruppenchats sind in Echtzeit möglich.',
        },
        {
          title: 'Benachrichtigungen',
          description:
            'In Echtzeit über Likes, Reposts, Nachrichten und Follows benachrichtigt werden.',
        },
      ],
    },
    // {
    //   title: 'The Athlete',
    //   mainImg: athlete,
    //   secondaryImg: athlete2,
    //   url: '/work/the-athlete',
    //   infos: [
    //     {
    //       title: 'Truly A masterpiece',
    //       description:
    //         '“Lorem Ipsum is simply dummy text of the printing and typesetting industry.”',
    //     },
    //     {
    //       title: 'Fresh look on a brutal sport.',
    //       description:
    //         '“Lorem Ipsum is simply dummy text of the printing and typesetting industry.”',
    //     },
    //     {
    //       title: 'It’s okay lmao.',
    //       description:
    //         '“Lorem Ipsum is simply dummy text of the printing and typesetting industry.”',
    //     },
    //   ],
    // },
    // {
    //   title: 'Good Times',
    //   mainImg: goodtimes,
    //   url: '/work/good-times',
    //   secondaryImg: goodtimes2,
    //   infos: [
    //     {
    //       title: 'Truly A masterpiece',
    //       description:
    //         '“Lorem Ipsum is simply dummy text of the printing and typesetting industry.”',
    //     },
    //     {
    //       title: 'Fresh look on a brutal sport.',
    //       description:
    //         '“Lorem Ipsum is simply dummy text of the printing and typesetting industry.”',
    //     },
    //     {
    //       title: 'It’s okay lmao.',
    //       description:
    //         '“Lorem Ipsum is simply dummy text of the printing and typesetting industry.”',
    //     },
    //   ],
    // },
    // {
    //   title: 'The Racer',
    //   mainImg: theracer,
    //   url: '/work/the-racer',
    //   secondaryImg: theracer2,
    //   infos: [
    //     {
    //       title: 'Truly A masterpiece',
    //       description:
    //         '“Lorem Ipsum is simply dummy text of the printing and typesetting industry.”',
    //     },
    //     {
    //       title: 'Fresh look on a brutal sport.',
    //       description:
    //         '“Lorem Ipsum is simply dummy text of the printing and typesetting industry.”',
    //     },
    //     {
    //       title: 'It’s okay lmao.',
    //       description:
    //         '“Lorem Ipsum is simply dummy text of the printing and typesetting industry.”',
    //     },
    //   ],
    // },
  ];
};
