import React from 'react';
import styled from 'styled-components';
// import { motion } from 'framer-motion';
import { StyledAbout, StyledDescription, StyledImage } from '../styles';
import { scrollReveal } from '../animation';
import { useScroll } from './useScroll';
// import clock from '../img/clock.svg';
import diaphragm from '../img/diaphragm.svg';
import money from '../img/money.svg';
import teamwork from '../img/teamwork.svg';
import wrench from '../img/wrench.png';
// import home2 from '../img/home2.png';
import deskCoding from '../img/svg-2.svg';

const ServicesComponent = () => {
  const [element, controls] = useScroll();
  return (
    <StyledServices
      variants={scrollReveal}
      animate={controls}
      initial='hidden'
      ref={element}
    >
      <StyledDescription>
        <h2>
          Hohe <span>Qualität</span>
        </h2>
        <StyledCards>
          <StyledCard>
            <div className='icon'>
              <img src={wrench} alt='img' />
              <h3>Personalisiert</h3>
            </div>
            <p>Erstellung nach Deinen Wünschen.</p>
          </StyledCard>

          <StyledCard>
            <div className='icon'>
              <img src={teamwork} alt='img' />
              <h3>Teamwork</h3>
            </div>
            <p>Gemeinsame Erarbeitung.</p>
          </StyledCard>

          <StyledCard>
            <div className='icon'>
              <img src={diaphragm} alt='img' />
              <h3>Betreuung</h3>
            </div>
            <p>Von der Erstellung bis zum Hosting.</p>
          </StyledCard>

          <StyledCard>
            <div className='icon'>
              <img src={money} alt='img' />
              <h3>Bezahlbar</h3>
            </div>
            <p>Kosten angepasst an Dein Budget.</p>
          </StyledCard>
        </StyledCards>
      </StyledDescription>

      <StyledServicesImage>
        <img src={deskCoding} alt='img' />
      </StyledServicesImage>
    </StyledServices>
  );
};

const StyledServices = styled(StyledAbout)`
  h2 {
    padding-bottom: 5rem;
  }

  p {
    width: 70%;
    padding: 2rem 0 4rem;
  }
`;

const StyledCards = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1300px) {
    justify-content: center;
  }
`;

const StyledCard = styled.div`
  flex-basis: 20rem;

  .icon {
    display: flex;
    align-items: center;

    img {
      height: 50px;
      object-fit: cover;
    }

    h3 {
      margin-left: 1rem;
      background-color: #fff;
      color: #000;
      padding: 1rem;

      &:hover {
        transition-timing-function: ease-in-out;
        transition: 0.2s;
        transform: scale(1.1);
        cursor: default;
      }
    }
  }
`;

const StyledServicesImage = styled(StyledImage)`
  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1300px) {
    width: 50%;
    height: 50%;
    margin: auto;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

export default ServicesComponent;
