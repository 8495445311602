import React from 'react';
import { AnimatePresence } from 'framer-motion';
import AboutUs from './pages/AboutUs';
import { GlobalStyle } from './components/GlobalStyle';
import Nav from './components/Nav';
import Footer from './components/Footer';
import { Switch, Route, useLocation } from 'react-router-dom';
import OurWork from './pages/OurWork';
import ContactUs from './pages/ContactUs';
import ReferenceDetail from './pages/ReferenceDetail';
import Imprint from './pages/Imprint';
import Privacy from './pages/Privacy';

function App() {
  const location = useLocation();

  window.onload = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  };

  return (
    <>
      <GlobalStyle />
      <Nav />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path='/' component={AboutUs} exact />
          <Route path='/work' component={OurWork} exact />
          <Route path='/work/:id' component={ReferenceDetail} />
          <Route path='/contact' component={ContactUs} />
          <Route path='/imprint' component={Imprint} />
          <Route path='/privacy' component={Privacy} />
        </Switch>
      </AnimatePresence>
      <Footer />
    </>
  );
}

export default App;
