import React from 'react';
// import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import { useLocation } from 'react-router';
import logo from '../img/logo_fh_small.png';
import xingIcon from '../img/XING_icon.png';

const Footer = () => {
  // const { pathname } = useLocation();

  return (
    <StyledNav>
      <StyledImage>
        <img src={logo} alt='logo' />
        <span>&copy; {new Date().getFullYear()}</span>
      </StyledImage>
      <ul>
        <li className='footer-desktop'>
          <Link to='/privacy'>Datenschutz</Link>
        </li>
        <li>
          <StyledIcons>
            <a
              href='https://www.xing.com/profile/Florian_Harten/cv'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={xingIcon} alt='xing' />
            </a>
          </StyledIcons>
        </li>

        <li>
          <StyledIcons>
            <a
              href='https://www.linkedin.com/in/florian-harten-964a86160/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='fab fa-linkedin-in' />
            </a>
          </StyledIcons>
        </li>
        <li>
          <StyledIcons>
            <a
              href='https://twitter.com/FlorianHarten'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='fab fa-twitter' />
            </a>
          </StyledIcons>
        </li>

        <li className='footer-desktop'>
          <Link to='/imprint'>Impressum</Link>
        </li>
      </ul>

      <ul className='footer-mobile'>
        <li>
          <Link to='/privacy'>Datenschutz</Link>
        </li>

        <li>
          <Link to='/imprint'>Impressum</Link>
        </li>
      </ul>
    </StyledNav>
  );
};

const StyledNav = styled.div`
  z-index: 999;
  min-height: 10vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  background-color: #282828;
  flex-direction: column;
  padding: 2rem 1rem;

  a {
    color: #fff;
    text-decoration: none;
  }

  ul {
    display: flex;
    list-style: none;
    padding: 2rem 2rem 1rem;
    justify-content: space-around;
    width: 100%;

    li {
      display: flex;
      padding: 0;
      align-items: center;
    }
  }

  #logo {
    display: inline-block;
    margin: 1rem;
    font-size: 1.5rem;
    font-family: 'Lobster', cursive;
    font-weight: lighter;
  }

  #logo {
    span {
      color: #fff;
      font-family: 'Inter', sans-serif;
      font-size: 13px;
    }
  }

  .footer-mobile {
    display: none;
    padding-top: 0;
  }

  @media (max-width: 768px) {
    .footer-desktop {
      display: none;
    }

    .footer-mobile {
      display: inherit;
    }
  }
`;

const StyledImage = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  z-index: 2;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 40px;
    /* border-radius: 50%; */
    object-fit: cover;
  }

  span {
    width: 100%;
    margin-left: 1rem;
    white-space: nowrap;
  }
`;

const StyledIcons = styled.div`
  display: flex;

  img {
    width: 100%;
    height: 20px;
    object-fit: cover;
    padding: 0 2rem;
  }

  i {
    font-size: 1.5rem;
    padding: 0 2rem;
  }
`;

export default Footer;
