import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import logo from '../img/logo_fh_small.png';
import * as palette from './GlobalStyle';

const Nav = () => {
  const { pathname } = useLocation();

  return (
    <StyledNav>
      <h1>
        <Link id='logo' to='/'>
          {/* <span>{'{ '}</span>fh<span>{' }'}</span> */}
          <StyledImage>
            <img src={logo} alt='logo' />
          </StyledImage>
        </Link>
      </h1>
      <ul>
        <li>
          <Link to='/'>Überblick</Link>
          <StyledLine
            initial={{ width: '0%' }}
            animate={{
              width: pathname === '/' ? '50%' : '0%',
              opacity: pathname === '/' ? 1 : 0,
            }}
            transition={{ duration: 0.75 }}
          />
        </li>
        <li>
          <Link to='/work'>Portfolio</Link>
          <StyledLine
            initial={{ width: '0%' }}
            animate={{
              width: pathname.startsWith('/work') ? '50%' : '0%',
              opacity: pathname.startsWith('/work') ? 1 : 0,
            }}
            transition={{ duration: 0.75 }}
          />
        </li>
        <li>
          <Link to='/contact'>Kontakt</Link>
          <StyledLine
            initial={{ width: '0%' }}
            animate={{
              width: pathname === '/contact' ? '50%' : '0%',
              opacity: pathname === '/contact' ? 1 : 0,
            }}
            transition={{ duration: 0.75 }}
          />
        </li>
      </ul>
    </StyledNav>
  );
};

const StyledNav = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  min-height: 5vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10rem;
  background-color: #282828;

  a {
    color: #fff;
    text-decoration: none;
  }

  ul {
    display: flex;
    list-style: none;
  }

  #logo {
    font-size: 0.5rem;
    font-family: 'Lobster', cursive;
    font-weight: lighter;

    span {
      font-family: 'Inter', sans-serif;
    }
  }

  li {
    padding-left: 10rem;
    position: relative;
  }

  @media (max-width: 1300px) {
    flex-direction: column;
    padding: 2rem 1rem;
    #logo {
      /* display: inline-block; */
      display: contents;
      margin: 1rem;
    }
    ul {
      padding: 2rem;
      justify-content: space-around;
      width: 100%;
      li {
        padding: 0;
      }
    }
  }
`;

const StyledImage = styled.div`
  flex: 1;
  overflow: hidden;
  z-index: 2;

  img {
    width: 100%;
    height: 50px;
    /* border-radius: 50%; */
    object-fit: cover;
  }
`;

const StyledLine = styled(motion.div)`
  height: 0.3rem;
  background-color: ${palette.green};
  width: 0%;
  position: absolute;
  bottom: -80%;
  left: 60%;

  @media (max-width: 1300px) {
    left: 0%;
  }
`;

export default Nav;
