import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import * as palette from '../components/GlobalStyle';

import {
  pageAnimation,
  fadeAnimation,
  photoAnimation,
  lineAnimation,
  sliderContainerAnimation,
  sliderAnimation,
  hideButtons,
} from '../animation';
import { useScroll } from '../components/useScroll';
// import { ReferenceData } from '../referenceData';
import ScrollToTop from '../components/ScrollToTop';
// import athlete from '../img/athlete-small.png';
// import theracer from '../img/theracer-small.png';
// import goodtimes from '../img/goodtimes-small.png';
import breathmohr from '../img/breathmohr.JPG';
import thoughts from '../img/thoughts.JPG';
// import h2h from '../img/h2h.jpg';
import h2h from '../img/happy2help_next.png';

const OurWork = () => {
  const [element, controls] = useScroll();
  const [element2, controls2] = useScroll();
  // const [references] = useState(ReferenceData);
  return (
    <StyledWork
      variants={pageAnimation}
      initial='hidden'
      animate='show'
      exit='exit'
      style={{ backgroundColor: '#fff' }}
    >
      <motion.div variants={sliderContainerAnimation}>
        {/* <StyledFrame0 variants={sliderAnimation} /> */}
        <StyledFrame1 variants={sliderAnimation} />
        <StyledFrame2 variants={sliderAnimation} />
        <StyledFrame3 variants={sliderAnimation} />
        <StyledFrame4 variants={sliderAnimation} />
      </motion.div>

      <StyledReference>
        <motion.h2 variants={fadeAnimation}>Breathe Mohr</motion.h2>
        <motion.div variants={lineAnimation} className='line'></motion.div>
        <StyledHide>
          <motion.img
            variants={photoAnimation}
            src={breathmohr}
            alt='breathe mohr'
          />
          <motion.div variants={hideButtons}>
            <a
              href='https://breathe-mohr.de'
              target='_blank'
              rel='noopener noreferrer'
            >
              <motion.button className='btn-link'>Zur Website</motion.button>
            </a>
            <Link to='/work/breathe-mohr'>
              <motion.button className='btn-more'>Mehr Infos</motion.button>
            </Link>
          </motion.div>
        </StyledHide>
      </StyledReference>

      <StyledReference
        ref={element}
        variants={fadeAnimation}
        animate={controls}
        initial='hidden'
      >
        <motion.h2 variants={fadeAnimation}>happy2help</motion.h2>
        <motion.div variants={lineAnimation} className='line'></motion.div>
        <StyledHide>
          <img src={h2h} alt='happy2help' />
          <motion.div variants={hideButtons}>
            <a
              href='https://happy-2-help.org'
              target='_blank'
              rel='noopener noreferrer'
            >
              <motion.button className='btn-link'>Zur Website</motion.button>
            </a>
            <Link to='/work/happy2help'>
              <motion.button className='btn-more'>Mehr Infos</motion.button>
            </Link>
          </motion.div>
        </StyledHide>
      </StyledReference>

      <StyledReference
        ref={element2}
        variants={fadeAnimation}
        animate={controls2}
        initial='hidden'
      >
        <motion.h2 variants={fadeAnimation}>thoughts</motion.h2>
        <motion.div variants={lineAnimation} className='line'></motion.div>
        <StyledHide>
          <img src={thoughts} alt='thoughts' />
          <motion.div variants={hideButtons}>
            <a
              href='https://thoughts.flzn.de'
              target='_blank'
              rel='noopener noreferrer'
            >
              <motion.button className='btn-link'>Zur Website</motion.button>
            </a>
            <Link to='/work/thoughts'>
              <motion.button className='btn-more'>Mehr Infos</motion.button>
            </Link>
          </motion.div>
        </StyledHide>
      </StyledReference>
      <ScrollToTop />
    </StyledWork>
  );
};

const StyledWork = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 5rem 10rem;

  h2 {
    padding: 1rem 0;
  }

  @media (max-width: 1300px) {
    padding: 2rem;
  }
`;

const StyledReference = styled(motion.div)`
  position: relative;
  padding-bottom: 10rem;

  .line {
    height: 0.5rem;
    background-color: ${palette.green};
    margin-bottom: 3rem;
  }

  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    object-position: top;
    /* filter: brightness(100%);
    transition: all 0.75s ease;
    -webkit-transition: all 0.75s ease; */
  }

  button {
    position: absolute;
    top: 50%;
    opacity: 0;
    /* color: #000; */
  }

  .btn-link {
    left: 30%;
  }

  .btn-more {
    left: 60%;
  }

  /* &:hover {
    img {
      filter: brightness(30%);
      -webkit-filter: brightness(30%);
    }

    button {
      opacity: 1;

      &:hover {
        color: #fff;
      }
    }
  } */
`;

const StyledHide = styled.div`
  overflow: hidden;

  img {
    filter: brightness(100%);
    transition: all 0.75s ease;
    -webkit-transition: all 0.75s ease;
  }

  &:hover {
    img {
      filter: brightness(30%);
      -webkit-filter: brightness(30%);
    }

    button {
      opacity: 1;
    }
  }
`;

// FRAME ANIMATION
const StyledFrame1 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 10%;
  width: 100%;
  height: 100vh;
  background-color: #fffebf;
  z-index: 2;
`;

// const StyledFrame0 = styled(StyledFrame1)`
//   background-color: #fff;
// `;

const StyledFrame2 = styled(StyledFrame1)`
  background-color: #ff8efb;
`;

const StyledFrame3 = styled(StyledFrame1)`
  background-color: #8ed2ff;
`;
const StyledFrame4 = styled(StyledFrame1)`
  background-color: #8effa0;
`;

export default OurWork;
