import { useEffect } from 'react';
// import { useLocation } from 'react-router';

const ScrollToTop = () => {
  //   const { pathname } = useLocation();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  //   window.onload = () => {
  //     window.scroll({
  //       top: 0,
  //       left: 0,
  //     });
  //     console.log('hi');
  //   };

  return null;
};

export default ScrollToTop;
