import React from 'react';
import { motion } from 'framer-motion';
import { pageAnimation } from '../animation';
import AboutComponent from '../components/AboutComponent';
import FaqComponent from '../components/FaqComponent';
import ServicesComponent from '../components/ServicesComponent';
import ScrollToTop from '../components/ScrollToTop';
import { StyledHide } from '../styles';

const AboutUs = () => {
  return (
    <motion.div
      variants={pageAnimation}
      initial='hidden'
      animate='show'
      exit='exit'
    >
      <AboutComponent />
      <StyledHide>
        <ServicesComponent />
        <FaqComponent />
      </StyledHide>
      <ScrollToTop />
    </motion.div>
  );
};

export default AboutUs;
