import React from 'react';
import styled from 'styled-components';
import { StyledAbout, StyledDescription } from '../styles';

const Privacy = () => {
  return (
    <StyledAbout>
      <StyledDescription>
        <PrivacyContainer>
          <h2>Datenschutzerklärung</h2>
          <StyledText>
            Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere
            der EU-Datenschutzgrundverordnung (DSGVO), ist: <br /> Florian
            Harten.
          </StyledText>
          <h4>Ihre Betroffenenrechte</h4>
          <StyledText>
            Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
            können Sie jederzeit folgende Rechte ausüben: Auskunft über Ihre bei
            uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),
            Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
            Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),
            Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
            gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
            Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21
            DSGVO) und Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
            eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben
            (Art. 20 DSGVO). Sofern Sie uns eine Einwilligung erteilt haben,
            können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.
            Sie können sich jederzeit mit einer Beschwerde an eine
            Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde
            des Bundeslands Ihres Wohnsitzes oder an die für uns als
            verantwortliche Stelle zuständige Behörde. Eine Liste der
            Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift
            finden Sie unter: <br />
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'
            >
              https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
            </a>
          </StyledText>
          <h4>Kontaktformular</h4>
          <h3>Art und Zweck der Verarbeitung:</h3>
          <StyledText>
            Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen
            Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer
            validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient
            der Zuordnung der Anfrage und der anschließenden Beantwortung
            derselben. Die Angabe weiterer Daten ist optional.
          </StyledText>
          <h3>Rechtsgrundlage:</h3>
          <StyledText>
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten
            erfolgt auf der Grundlage eines berechtigten Interesses (Art 6 Abs.
            1 lit. f DSGVO). Durch Bereitstellung des Kontaktformulars möchten
            wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre
            gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage
            sowie für mögliche Anschlussfragen gespeichert. Sofern Sie mit uns
            Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die
            Verarbeitung der in das Kontaktformular eingegebenen Daten zur
            Durchführung vorvertraglicher Maßnahmen (Art 6 Abs. 1 lit. b DSGVO).
          </StyledText>
          <h3>Empfänger:</h3>
          <StyledText>
            Empfänger der Daten sind ggf. Auftragsverarbeiter.
          </StyledText>
          <h3>Speicherdauer:</h3>
          <StyledText>
            Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage
            gelöscht. Sofern es zu einem Vertragsverhältnis kommt, unterliegen
            wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre
            Daten nach Ablauf dieser Fristen.
          </StyledText>
          <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
          <StyledText>
            Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig.
            Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren
            Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
          </StyledText>
          <h3>SSL-Verschlüsselung</h3>
          <StyledText>
            Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
            verwenden wir dem aktuellen Stand der Technik entsprechende
            Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
          </StyledText>
          <h3>Änderung unserer Datenschutzbestimmungen</h3>
          <StyledText>
            Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit
            sie stets den aktuellen rechtlichen Anforderungen entspricht oder um
            Änderungen unserer Leistungen in der Datenschutzerklärung
            umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren
            erneuten Besuch gilt dann die neue Datenschutzerklärung.
          </StyledText>
          <h3>Fragen an den Datenschutzbeauftragten</h3>
          <StyledText>
            Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
            E-Mail oder wenden Sie sich direkt an die für den Datenschutz
            verantwortliche Person in unserer Organisation: <br />
            Kontakt:{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='mailto:post@florian-harten.de'
            >
              post@florian-harten.de
            </a>
          </StyledText>
        </PrivacyContainer>
      </StyledDescription>
    </StyledAbout>
  );
};

export const PrivacyContainer = styled.div`
  justify-content: center;
  height: 100%;
  padding: 40px 10rem;

  @media screen and (max-width: 1000px) {
    padding: 40px 5rem;
  }
`;

export const PrivacyH1 = styled.h1`
  text-align: center;
  margin-bottom: 48px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  /* font-size: 2.5rem; */
  /* color: #000; */

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

// export const h4 = styled.h4`
//   font-size: 1.5rem;
//   margin: 24px auto;
// `;

// export const h3 = styled.h3`
//   font-size: 1rem;
//   margin: 24px auto;
// `;

export const StyledText = styled.p`
  font-size: 1rem;
  text-align: left;

  a {
    text-decoration: none;
    font-weight: bold;
    color: #fff;
  }
`;

export default Privacy;
