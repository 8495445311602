import React from 'react';
import styled from 'styled-components';
import { AnimateSharedLayout } from 'framer-motion';
import { StyledAbout } from '../styles';
import { useScroll } from './useScroll';
import { scrollReveal } from '../animation';
import ToggleComponent from './ToggleComponent';

const FaqComponent = () => {
  const [element, controls] = useScroll();

  return (
    <StyledFaq
      variants={scrollReveal}
      animate={controls}
      initial='hidden'
      ref={element}
    >
      <h2>
        Fragen? <span>FAQ</span>
      </h2>
      <AnimateSharedLayout>
        <ToggleComponent title='Wie ist der Ablauf?'>
          <div className='answer'>
            <p>Es startet mit Deiner Idee.</p>
            <p>
              Zusammen klären wir ganz unverbindlich, was du dir vorstellst und
              wie es umgesetzt werden könnte. Daraus ergeben sich ein Zeitplan
              und der Preis.
            </p>
          </div>
        </ToggleComponent>

        {/* <ToggleComponent title='Daily Schedule?'>
          <div className='answer'>
            <p>Lorem ipsum dolor sit amet.</p>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo,
              vitae!
            </p>
          </div>
        </ToggleComponent> */}

        <ToggleComponent title='Welche Dienstleistungen werden angeboten?'>
          <div className='answer'>
            <p>Ich begleite Dich durch den gesamten Prozess.</p>
            <p>
              Von der Beratung vor der Erstellung der Website über die
              Programmierung bis hin zu Hilfe beim Hosting Deiner neuen Seite
              unterstütze ich Dich gerne.
            </p>
          </div>
        </ToggleComponent>

        <ToggleComponent title='Was kostet eine Homepage?'>
          <div className='answer'>
            <p>Das lässt sich pauschal nicht beantworten.</p>
            <p>
              Schreib mir ganz unverbindlich mit Deiner Idee oder Deinen Fragen
              und wir schauen gemeinsam, welchen Umfang das Projekt hätte.
            </p>
          </div>
        </ToggleComponent>
      </AnimateSharedLayout>
    </StyledFaq>
  );
};

const StyledFaq = styled(StyledAbout)`
  display: block;

  span {
    display: block;
  }

  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }

  .faq-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0;
    width: 100%;
  }

  .question {
    padding: 3rem 0;
    cursor: pointer;
  }

  .answer {
    padding: 2rem 0;

    p {
      padding: 1rem 0;
    }
  }
`;

export default FaqComponent;
