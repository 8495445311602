import React from 'react';
import styled from 'styled-components';
import { StyledAbout, StyledDescription } from '../styles';

const Imprint = () => {
  return (
    <StyledAbout>
      <StyledDescription>
        <StyledContainer>
          <h2 style={{ textAlign: 'center' }}>Impressum</h2>
          <StyledText style={{ textAlign: 'right' }}>
            Florian Harten <br />
            Rellinger Str. 52 <br />
            20257 Hamburg <br />
            {/* Telefon: 01xx xxxxxxx <br /> */}
            {/* E-Mail:{' '} */}
            <a href='mailto:post@florian-harten.de'>post@florian-harten.de</a>
          </StyledText>
          <h2>Disclaimer – rechtliche Hinweise</h2>
          <StyledText>
            § 1 Warnhinweis zu Inhalten Die kostenlosen und frei zugänglichen
            Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt.
            Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die
            Richtigkeit und Aktualität der bereitgestellten kostenlosen und frei
            zugänglichen journalistischen Ratgeber und Nachrichten. Namentlich
            gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und
            nicht immer die Meinung des Anbieters wieder. Allein durch den
            Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei
            Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande,
            insoweit fehlt es am Rechtsbindungswillen des Anbieters. <br />
            <br />§ 2 Externe Links Diese Website enthält Verknüpfungen zu
            Websites Dritter („externe Links“). Diese Websites unterliegen der
            Haftung der jeweiligen Betreiber. Der Anbieter hat bei der
            erstmaligen Verknüpfung der externen Links die fremden Inhalte
            daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem
            Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat
            keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und
            auf die Inhalte der verknüpften Seiten. Das Setzen von externen
            Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis
            oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle
            der externen Links ist für den Anbieter ohne konkrete Hinweise auf
            Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen
            werden jedoch derartige externe Links unverzüglich gelöscht. <br />
            <br />
            § 3 Urheber- und Leistungsschutzrechte Die auf dieser Website
            veröffentlichten Inhalte unterliegen dem deutschen Urheber- und
            Leistungsschutzrecht. Jede vom deutschen Urheber- und
            Leistungsschutzrecht nicht zugelassene Verwertung bedarf der
            vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen
            Rechteinhabers. Dies gilt insbesondere für Vervielfältigung,
            Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw.
            Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen
            Medien und Systemen. Inhalte und Rechte Dritter sind dabei als
            solche gekennzeichnet. Die unerlaubte Vervielfältigung oder
            Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht
            gestattet und strafbar. Lediglich die Herstellung von Kopien und
            Downloads für den persönlichen, privaten und nicht kommerziellen
            Gebrauch ist erlaubt. Die Darstellung dieser Website in fremden
            Frames ist nur mit schriftlicher Erlaubnis zulässig. <br />
            <br />§ 4 Besondere Nutzungsbedingungen Soweit besondere Bedingungen
            für einzelne Nutzungen dieser Website von den vorgenannten
            Paragraphen abweichen, wird an entsprechender Stelle ausdrücklich
            darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall
            die besonderen Nutzungsbedingungen.
          </StyledText>

          {/* <h2>Icons</h2>
          <StyledText>
            Icons erstellt von{' '}
            <a href='https://www.freepik.com' title='Freepik'>
              Freepik
            </a>{' '}
            from{' '}
            <a href='https://www.flaticon.com/de/' title='Flaticon'>
              www.flaticon.com
            </a>
          </StyledText> */}
        </StyledContainer>
      </StyledDescription>
    </StyledAbout>
  );
};

export const StyledContainer = styled.div`
  /* justify-content: center; */
  min-height: 90vh;
  padding: 40px 10rem;
  text-align: left;

  @media screen and (max-width: 1000px) {
    padding: 40px 5rem;
    margin-bottom: 700px;
  }
`;

export const StyledText = styled.p`
  font-size: 1rem;

  a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
`;

export default Imprint;
