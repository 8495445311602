import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { motion } from 'framer-motion';
import { pageAnimation } from '../animation';
import { ReferenceData } from '../referenceData';
import * as palette from '../components/GlobalStyle';

const ReferenceDetail = () => {
  const [references] = useState(ReferenceData);
  const [reference, setReference] = useState(null);

  const history = useHistory();
  //   const url = history.location.pathname;

  useEffect(() => {
    setReference(
      references.filter(
        (ReferenceData) => ReferenceData.url === history.location.pathname,
      )[0],
    );
  }, [history, references]);

  return (
    <>
      {!reference ? (
        <p>Loading</p> // TO DO: CREATE LOADER!
      ) : (
        <StyledDetails
          variants={pageAnimation}
          initial='hidden'
          animate='show'
          exit='exit'
        >
          <StyledHeadline>
            <h2>{reference.title}</h2>
            <StyledImage>
              <img src={reference.mainImg} alt={reference.title} />
            </StyledImage>
          </StyledHeadline>
          <StyledInfos>
            {reference.infos.map((info, index) => (
              <StyledInfo key={index}>
                <h3>{info.title}</h3>
                <div className='line'></div>
                <p>{info.description}</p>
              </StyledInfo>
            ))}
          </StyledInfos>

          <StyledImage>
            <img src={reference.secondaryImg} alt='img' />
          </StyledImage>
        </StyledDetails>
      )}
    </>
  );
};

const StyledDetails = styled(motion.div)`
  color: #fff;
  margin-bottom: 5rem;
`;

const StyledHeadline = styled.div`
  max-height: 90vh;
  padding-top: 20vh;
  position: relative;

  h2 {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
  }

  /* img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    object-position: top;
  } */
`;

const StyledInfos = styled.div`
  min-height: 30vh;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 5rem 10rem;

  @media (max-width: 1500px) {
    display: block;
    margin: 2rem 2rem;
  }
`;

const StyledInfo = styled.div`
  padding: 5rem;
  flex: 1;

  h3 {
    font-size: 2rem;
  }

  p {
    padding: 2rem 0;
  }

  .line {
    width: 100%;
    background-color: ${palette.green};
    height: 0.5rem;
    margin: 1rem 0;
  }
`;

const StyledImage = styled.div`
  min-height: 50vh;
  margin: 0 7rem;

  img {
    width: 100%;
    max-height: 70vh;
    object-fit: cover;
    object-position: top;
  }
`;

export default ReferenceDetail;
