import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import { pageAnimation } from '../animation';
import ScrollToTop from '../components/ScrollToTop';
import * as palette from '../components/GlobalStyle';

const ContactUs = () => {
  const [messageSent, setMessageSent] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const history = useHistory();

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'gmail',
        'template_t98zjsn',
        e.target,
        'user_fnSXQDFPRK5V4d61CHwRu',
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessageError(false);
          setMessageSent(true);

          setTimeout(() => {
            history.push('/');
            e.target.reset();
          }, 10000);
        },
        (error) => {
          console.log(error.text);
          setMessageSent(false);
          setMessageError(true);

          setTimeout(() => {
            history.push('/');
            e.target.reset();
          }, 5000);
        },
      );
  }

  return (
    <StyledContact
      variants={pageAnimation}
      initial='hidden'
      animate='show'
      exit='exit'
      style={{ backgroundColor: '#fff' }}
    >
      {messageError || messageSent ? (
        <h4 style={{ padding: '25%' }}>
          {messageSent ? (
            <div>
              Vielen Dank für Deine Nachricht. Ich werde mich sobald wie möglich
              bei dir melden.
              <br /> <br />
              Automatische Weiterleitung in 5 Sekunden.
            </div>
          ) : (
            <div style={{ color: 'coral' }}>
              Das hat leider nicht funktioniert. Bitte versuche es später
              nochmal.
              <br /> <br />
              Automatische Weiterleitung in 5 Sekunden.
            </div>
          )}
        </h4>
      ) : (
        <StyledHide>
          <Container id='contact'>
            <FormWrap>
              <FormContent>
                <Form onSubmit={sendEmail}>
                  <FormH1
                    data-aos='fade-right'
                    data-aos-duration='1200'
                    data-aos-delay='300'
                    data-aos-once='true'
                    data-aos-anchor-placement='center-bottom'
                  >
                    Kontakt aufnehmen
                  </FormH1>
                  <FormLabel
                    htmlFor='for'
                    data-aos='fade-left'
                    data-aos-duration='1200'
                    data-aos-delay='300'
                    data-aos-once='true'
                    data-aos-anchor-placement='center-bottom'
                  >
                    Name
                  </FormLabel>
                  <FormInput
                    name='user_name'
                    required
                    data-aos='fade-left'
                    data-aos-duration='1200'
                    data-aos-delay='300'
                    data-aos-once='true'
                    data-aos-anchor-placement='center-bottom'
                  />
                  <FormLabel
                    htmlFor='for'
                    data-aos='fade-left'
                    data-aos-duration='1200'
                    data-aos-delay='300'
                    data-aos-once='true'
                    data-aos-anchor-placement='center-bottom'
                  >
                    Email
                  </FormLabel>
                  <FormInput
                    name='user_email'
                    required
                    data-aos='fade-left'
                    data-aos-duration='1200'
                    data-aos-delay='300'
                    data-aos-once='true'
                    data-aos-anchor-placement='center-bottom'
                  />
                  <FormLabel
                    htmlFor='for'
                    data-aos='fade-left'
                    data-aos-duration='1200'
                    data-aos-delay='300'
                    data-aos-once='true'
                    data-aos-anchor-placement='center-bottom'
                  >
                    Nachricht
                  </FormLabel>
                  <FormTextArea
                    name='message'
                    label='Message'
                    required
                    data-aos='fade-left'
                    data-aos-duration='1200'
                    data-aos-delay='300'
                    data-aos-once='true'
                    data-aos-anchor-placement='center-bottom'
                  />
                  <FormButton
                    type='submit'
                    data-aos='fade-right'
                    data-aos-duration='1200'
                    data-aos-delay='300'
                    data-aos-once='true'
                    data-aos-anchor-placement='center-bottom'
                  >
                    Abschicken
                  </FormButton>
                </Form>
              </FormContent>
            </FormWrap>
          </Container>
        </StyledHide>
      )}
      <ScrollToTop />
    </StyledContact>
  );
};

const StyledContact = styled(motion.div)`
  padding: 5rem 10rem;
  color: #353535;
  min-height: 90vh;

  @media (max-width: 1500px) {
    padding: 2rem;
    font-size: 1rem;
  }
`;

const StyledHide = styled.div`
  overflow: hidden;
`;

const Container = styled.div`
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 80px) {
    height: 1300;
  }
`;

const FormWrap = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0 50px;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

const Form = styled.form`
  background: ${palette.darkGrey};
  max-width: 800px;
  min-width: 600px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 80px 32px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

  @media screen and (max-width: 768px) {
    max-width: 700px;
  }

  @media screen and (max-width: 400px) {
    padding: 32px 32px;
    max-width: 300px;
    min-width: 200px;
  }
`;

const FormH1 = styled.h1`
  margin-bottom: 40px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  color: #fff;
`;

const FormInput = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: none;
  border-radius: 4px;
`;

const FormTextArea = styled.textarea`
  background-color: #eee;
  width: 100%;
  min-height: 150px;
  resize: none;
  border: none;
  border-radius: 4px;
  padding: 16px 16px;
  margin-bottom: 32px;
`;

const FormButton = styled.button`
  background: #01bf71;
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`;

export default ContactUs;
