import React from 'react';
import { motion } from 'framer-motion';
import { pageAnimation } from '../animation';
import PrivacyComponent from '../components/PrivacyComponent';
import ScrollToTop from '../components/ScrollToTop';

const Privacy = () => {
  return (
    <motion.div
      variants={pageAnimation}
      initial='hidden'
      animate='show'
      exit='exit'
    >
      <PrivacyComponent />
      <ScrollToTop />
    </motion.div>
  );
};

export default Privacy;
