import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { titleAnimation, fadeAnimation, photoAnimation } from '../animation';
import {
  StyledAbout,
  StyledDescription,
  StyledHide,
  StyledImage,
} from '../styles';
// import home1 from '../img/home1.png';
import floFrei from '../img/flo_frei.png';
import Wave from './WaveComponent';

const AboutComponent = () => {
  return (
    <StyledAbout>
      <StyledDescription>
        <motion.div>
          <StyledHide>
            <motion.h2 variants={titleAnimation}>Die Website</motion.h2>
          </StyledHide>
          <StyledHide>
            <motion.h2 variants={titleAnimation}>
              Deiner <span>Träume</span>
            </motion.h2>
          </StyledHide>
          {/* <StyledHide>
            <motion.h2 variants={titleAnimation}>come true</motion.h2>
          </StyledHide> */}
        </motion.div>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { delay: 2, duration: 1.75, ease: 'easeOut' },
          }}
        >
          Kontaktiere mich mit Deinen Ideen. Zusammen erarbeiten wir die
          perfekte Homepage für Dich.
        </motion.p>

        <Link to='/contact'>
          <motion.button variants={fadeAnimation}>Kontakt</motion.button>
        </Link>
      </StyledDescription>

      <StyledImage>
        <StyledImg variants={photoAnimation} src={floFrei} alt='img' />
      </StyledImage>
      <Wave />
    </StyledAbout>
  );
};

const StyledImg = styled(motion.img)`
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
`;

export default AboutComponent;
