import React from 'react';
import { motion } from 'framer-motion';
import { pageAnimation } from '../animation';
import ImprintComponent from '../components/ImprintComponent';
import ScrollToTop from '../components/ScrollToTop';

const Imprint = () => {
  return (
    <motion.div
      variants={pageAnimation}
      initial='hidden'
      animate='show'
      exit='exit'
    >
      <ImprintComponent />
      <ScrollToTop />
    </motion.div>
  );
};

export default Imprint;
